import {
  Component,
  OnInit,
  ViewChild,
  inject,
  ViewChildren,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { ActivatedRoute, RouterLink, RouterOutlet } from '@angular/router';
import { NgScrollbar } from 'ngx-scrollbar';
import { Auth, authState, User } from '@angular/fire/auth';
import { IconDirective } from '@coreui/icons-angular';
import {
  ContainerComponent,
  CalloutComponent,
  INavData,
  ShadowOnScrollDirective,
  SidebarBrandComponent,
  SidebarComponent,
  SidebarFooterComponent,
  SidebarHeaderComponent,
  SidebarNavComponent,
  SidebarToggleDirective,
  SidebarTogglerDirective,
} from '@coreui/angular-pro';

import { DefaultFooterComponent, DefaultHeaderComponent } from './';
import { navItems } from './_nav';
import { Subscription, filter } from 'rxjs';
import { TranslocoService, translate } from '@jsverse/transloco';
import { SystemService } from 'src/app/services/system.service';

function isOverflown(element: HTMLElement) {
  return (
    element.scrollHeight > element.clientHeight ||
    element.scrollWidth > element.clientWidth
  );
}

@Component({
  selector: 'app-dashboard',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss'],
  standalone: true,
  imports: [
    CalloutComponent,
    ContainerComponent,
    DefaultHeaderComponent,
    DefaultFooterComponent,
    IconDirective,
    NgScrollbar,
    RouterLink,
    RouterOutlet,
    SidebarComponent,
    SidebarHeaderComponent,
    SidebarBrandComponent,
    SidebarNavComponent,
    SidebarFooterComponent,
    SidebarToggleDirective,
    SidebarTogglerDirective,
    ShadowOnScrollDirective,
  ],
})
export class DefaultLayoutComponent implements OnInit, OnChanges {
  @ViewChild('overflow') sidebar: any;
  public navRef = JSON.parse(JSON.stringify(navItems));
  public navItemsList = structuredClone(navItems);
  private auth: Auth = inject(Auth);
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;

  constructor(
    private activatedRoute: ActivatedRoute,
    private translateService: TranslocoService,
    public systemService: SystemService
  ) {
    // setting up authStateSubscription;
    this.authStateSubscription = this.authState$.subscribe(
      (aUser: User | null) => {
        //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
        console.log('Layout: authState', aUser);
        const loginIndex = 4;
        if (aUser === null) {
          Object.assign(navItems[loginIndex], { name: 'Login', url: '/login' });
          this.toggleNavItems(false);
          this.navItemsList = structuredClone(navItems);
        } else {
          console.log('Layout: sidebar', this.sidebar);
          console.log('Layout: setting Logout', this.navItemsList, navItems);
          Object.assign(navItems[loginIndex], {
            name: 'Logout',
            url: '/logout',
          });
          this.toggleNavItems(true);
          this.navItemsList = structuredClone(navItems);
        }
      }
    );
  }

  ngOnInit(): void {
    console.log('Layout: initialized');
    this.translateService.events$
      .pipe(
        filter(
          (event: any) =>
            event.type === 'translationLoadSuccess' ||
            event.type === 'langChanged'
        )
      )
      .subscribe(() => {
        console.log('Running translate');
        this.navRef.forEach((navItem: any, index: number) => {
          navItems[index].name = translate('layout.' + String(navItem.name));
          this.navItemsList = structuredClone(navItems);
        });
      });
  }
  ngOnChanges(changes: SimpleChanges): void {
    console.log('Layout: changes', changes);
  }

  /**
   * toggleNavItems will set whether a navItem is enabled or disabled
   * @param enabled
   */
  toggleNavItems(enabled: boolean) {
    const postsIndex = 2;
    const contractsIndex = 3;
    const profileIndex = 6;
    if (enabled) {
      Object.assign(navItems[postsIndex], { attributes: { disabled: false } });
      Object.assign(navItems[contractsIndex], {
        attributes: { disabled: false },
      });
      Object.assign(navItems[profileIndex], {
        attributes: { disabled: false },
      });
    } else {
      Object.assign(navItems[postsIndex], { attributes: { disabled: 'true' } });
      Object.assign(navItems[contractsIndex], {
        attributes: { disabled: 'true' },
      });
      Object.assign(navItems[profileIndex], {
        attributes: { disabled: 'true' },
      });
    }
  }

  onScrollbarUpdate($event: any) {
    // if ($event.verticalUsed) {
    // console.log('verticalUsed', $event.verticalUsed);
    // }
  }
}
