<!--<c-header class="mb-4 d-print-none" position="sticky">-->
<ng-container *transloco="let t; read 'layout'">
  <c-container [fluid]="true" class="border-bottom px-4">
    <button [cSidebarToggle]="sidebarId" cHeaderToggler class="btn" toggle="visible"
      style="margin-inline-start: -14px;">
      <svg cIcon name="cilMenu" size="lg"></svg>
    </button>
    <c-header-nav class="d-md-flex">
      <c-nav-item class="d-none d-sm-flex">
        <a cNavLink routerLink="/tracker" routerLinkActive="active">{{ t('Dashboard') }}</a>
      </c-nav-item>
      <c-nav-item class="d-none d-sm-flex">
        <a cNavLink routerLink="/market" routerLinkActive="active">{{ t('Market') }}</a>
      </c-nav-item>
      <c-nav-item class="d-block d-sm-none">
        <a cNavLink routerLink="/tracker" cTooltip="Price Tracker" [cTooltipVisible]="showMarketTracker"
          cTooltipTrigger="hover" routerLinkActive="active">
          <img src="assets/svg/tracker-{{currentMode}}.svg" height="24px" width="24px" />
        </a>
      </c-nav-item>
      <c-nav-item class="d-block d-sm-none">
        <a cNavLink routerLink="/market" cTooltip="Market Place" [cTooltipVisible]="showMarketPlace"
          cTooltipTrigger="hover" routerLinkActive="active">
          <img src="assets/svg/market-{{currentMode}}.svg" height="24px" width="24px" />
        </a>
      </c-nav-item>

    </c-header-nav>
    @if (userService.loggedIn){
    <c-header-nav class="d-md-flex">
      <c-nav-item class="d-none d-sm-flex">
        <a cNavLink routerLink="/posts" routerLinkActive="active">{{ t('Posts') }}</a>
      </c-nav-item>
      <c-nav-item class="d-none d-sm-flex">
        <a cNavLink routerLink="/contracts" routerLinkActive="active">{{ t('Contracts') }}</a>
      </c-nav-item>
      <c-nav-item class="d-block d-sm-none">
        <a cNavLink routerLink="/posts" cTooltip="My Posts" [cTooltipVisible]="showMyPosts" cTooltipTrigger="hover"
          routerLinkActive="active">
          <img src="assets/svg/posts-{{currentMode}}.svg" height="24px" width="24px" class="filter-gold" />
        </a>
      </c-nav-item>
      <c-nav-item class="d-block d-sm-none">
        <a cNavLink routerLink="/contracts" cTooltip="My Contracts" [cTooltipVisible]="showContracts"
          cTooltipTrigger="hover" routerLinkActive="active">
          <img src="assets/svg/contract-{{currentMode}}.svg" height="24px" width="24px" />
        </a>
      </c-nav-item>
    </c-header-nav>
    }
    @if(userService.loggedIn){
    <c-header-nav class="d-none d-md-flex ms-auto ">
      <a cNavLink (click)="toggleShowUpdates()" class="position-relative">
        <svg cIcon class="m-0" name="cilBell" size="lg"></svg>
        @if(notificationService.myNotifications.length > 0){
        <c-badge class="position-relative bottom-0" shape="rounded-pill"
          color="info">{{notificationService.myNotifications.length}}</c-badge>
        }
      </a>
      <!-- <a cNavLink>
          <svg cIcon class="my-1" name="cilList" size="lg"></svg>
        </a>
        <a cNavLink>
          <svg cIcon class="my-1" name="cilEnvelopeOpen" size="lg"></svg>
        </a> -->
    </c-header-nav>
    }@else{
    <c-header-nav class="d-none d-md-flex ms-auto ">
    </c-header-nav>
    }

    <c-header-nav class="ms-auto ms-md-0">
      <div class="nav-item py-1">
        <div class="vr h-100 mx-1 text-body text-opacity-75"></div>
      </div>
      <ng-container class="d-none d-sm-flex" *ngTemplateOutlet="themeDropdown" />
      <ng-container *ngTemplateOutlet="languageDropdown" />
      <div class="nav-item py-1">
        <div class="vr h-100 mx-1 text-body text-opacity-75"></div>
      </div>
    </c-header-nav>

    <c-header-nav class="mx-0">
      <ng-container *ngTemplateOutlet="userDropdown" />
    </c-header-nav>

  </c-container>
  <c-container [fluid]="true" class="px-4">
    <c-breadcrumb-router />
    <div>{{userService.userProfile.nameFirst}} {{userService.userProfile.nameLast}}</div>
  </c-container>
</ng-container>
<!--</c-header>-->

<ng-template #userDropdown>
  <c-dropdown [popperOptions]="{ placement: 'bottom-start' }" variant="nav-item" *transloco="let t; read 'layout'">

    <button [caret]="false" cDropdownToggle class="py-0 pe-0">
      @if(userService.userProfile.userID > ""){
      <c-avatar shape="rounded-1" [size]="'md'" src="{{userService.getImageURL()}}" status="success"
        textColor="primary" />
      }@else {
      <c-avatar shape="rounded-1" [size]="'md'" src="./assets/images/avatars/login-icon.jpg" status="success"
        textColor="primary" />
      }
    </button>
    @if(userService.userProfile.userID > ""){
    <ul cDropdownMenu class="pt-0 w-auto">
      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2 rounded-top">
          {{ t('Account')}}
        </h6>
      </li>
      <li>
        <a cDropdownItem (click)="toggleShowUpdates()">
          <svg cIcon class="me-2" name="cilBell"></svg>
          {{ t('Updates')}}
          @if(notificationService.myNotifications.length > 0){
          <c-badge class="ms-2 float-end" color="info">{{notificationService.myNotifications.length}}</c-badge>
          }
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/posts">
          <!-- <svg cIcon class="me-2" name="cilEnvelopeOpen"></svg> -->
          <img src="assets/svg/posts-{{currentMode}}.svg" height="24px" width="24px" class="filter-gold" />
          {{ t('Posts')}}
          @if(orderService.numPosts > 0){
          <c-badge class="ms-2 float-end" color="success">
            {{orderService.numPosts}}
          </c-badge>
          }
        </a>
      </li>
      <li>
        <a cDropdownItem routerLink="/contracts">
          <svg cIcon class="me-2" name="cilCreditCard"></svg>
          {{ t('To Pay')}}
          @if(contractsService.allContracts.funding.length > 0){
          <c-badge class="ms-2 float-end" color="secondary">
            {{contractsService.allContracts.funding.length}}
          </c-badge>
          }
        </a>
      </li>
      <li>
        <h6 cDropdownHeader class="bg-body-secondary text-body-secondary fw-semibold py-2">
          {{ t('Settings')}}
        </h6>
      </li>
      <li></li>
      <li>
        <a cDropdownItem routerLink="/profile">
          <svg cIcon class="me-2" name="cilUser"></svg>
          {{ t('Profile')}}
        </a>
      </li>
      <!-- <li>
        <a cDropdownItem routerLink="">
          <svg cIcon class="me-2" name="cilSettings"></svg>
          {{ t('Settings') }}
        </a>
      </li> -->
      <li>
        <hr cDropdownDivider />
      </li>
      <li>
        <a cDropdownItem routerLink="/logout">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          {{ t('Logout') }}
        </a>
      </li>
    </ul>
    } @else {
    <ul cDropdownMenu class="pt-0 w-auto">
      <li>
        <a cDropdownItem routerLink="/login">
          <svg cIcon class="me-2" name="cilAccountLogout"></svg>
          {{ t('Login') }}
        </a>
      </li>
    </ul>
    }
  </c-dropdown>
</ng-template>

<ng-template #themeDropdown>
  <c-dropdown alignment="end" variant="nav-item" *transloco="let t; read 'layout'">
    <button [caret]="false" cDropdownToggle>
      <svg cIcon [name]="icons()" size="lg"></svg>
    </button>
    <div cDropdownMenu>
      @for (mode of colorModes; track mode.name) {
      <button (click)="colorMode.set(mode.name)" [active]="colorMode()===mode.name" [routerLink]="[]" cDropdownItem
        class="d-flex align-items-center">
        <svg cIcon class="me-2" [name]="mode.icon" size="lg"></svg>
        {{ mode.text }}
      </button>
      }
    </div>
  </c-dropdown>
</ng-template>

<ng-template #languageDropdown>
  <c-dropdown alignment="end" variant="nav-item" *transloco="let t; read 'layout'">
    <button [caret]="false" cDropdownToggle>
      {{ language }}
    </button>
    <div cDropdownMenu>
      @for (dialect of languages; track dialect.name) {
      <button (click)="languageSet(dialect)" [active]="language===dialect.name" [routerLink]="[]" cDropdownItem
        class="d-flex align-items-center">
        {{ dialect.text }}
      </button>
      }
    </div>
  </c-dropdown>
</ng-template>


<c-toaster position="top-end" *transloco="let t; read 'layout'">
  <c-toast *ngFor="let entry of notificationService.myNotifications" [color]="setColor(entry.msgType)" [autohide]="true"
    [fade]="true" [visible]="showUpdates">
    <c-toast-header [closeButton]="true" (click)="deleteNotification(entry.key)">
      Lakal Alert
      <small class="ms-auto">{{entry.duration}} ago</small>
    </c-toast-header>
    <c-toast-body>{{entry.message}}</c-toast-body>
    <!--<button aria-label="close" cButtonClose white class="me-2 m-auto" (click)="deleteNotification(entry.key)"></button> -->
  </c-toast>
</c-toaster>