import {
  cilAccountLogout,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilArrowThickToBottom,
  cilArrowThickToTop,
  cilBasket,
  cilBell,
  cilBellExclamation,
  cilBold,
  cilBookmark,
  cilBook,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCash,
  cilChart,
  cilChartPie,
  cilCheck,
  cilChevronLeft,
  cilChevronRight,
  cilCloudDownload,
  cilCode,
  cilCommentSquare,
  cilContact,
  cilContrast,
  cilCreditCard,
  cilCircle,
  cilCursor,
  cilDescription,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilFile,
  cilGraph,
  cilGrid,
  cilHome,
  cilHandshake,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilIndustry,
  cilInfo,
  cilInput,
  cilItalic,
  cilJustifyCenter,
  cilLanguage,
  cilLayers,
  cilLibraryBuilding,
  cilList,
  cilListRich,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMediaRecord,
  cilMenu,
  cilMobile,
  cilMoney,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShortText,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTerrain,
  cilTrash,
  cilTruck,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
} from '@coreui/icons';

import { signet } from './signet';
import { logo } from './logo';

export const iconSubset = {
  cilAccountLogout,
  cilAlignCenter,
  cilAlignLeft,
  cilAlignRight,
  cilApplicationsSettings,
  cilArrowBottom,
  cilArrowRight,
  cilArrowTop,
  cilArrowThickToBottom,
  cilArrowThickToTop,
  cilBasket,
  cilBell,
  cilBellExclamation,
  cilBold,
  cilBookmark,
  cilBook,
  cilBuilding,
  cilCalculator,
  cilCalendar,
  cilCash,
  cilChart,
  cilChartPie,
  cilCheck,
  cilCircle,
  cilChevronLeft,
  cilChevronRight,
  cilCloudDownload,
  cilCode,
  cilCommentSquare,
  cilContact,
  cilContrast,
  cilCreditCard,
  cilCursor,
  cilDescription,
  cilDollar,
  cilDrop,
  cilEnvelopeClosed,
  cilEnvelopeOpen,
  cilFile,
  cilGraph,
  cilGrid,
  cilHome,
  cilHandshake,
  cilInbox,
  cilIndentDecrease,
  cilIndentIncrease,
  cilInfo,
  cilInput,
  cilIndustry,
  cilItalic,
  cilJustifyCenter,
  cilLanguage,
  cilLayers,
  cilLibraryBuilding,
  cilList,
  cilListRich,
  cilListNumbered,
  cilLocationPin,
  cilLockLocked,
  cilMagnifyingGlass,
  cilMap,
  cilMediaPlay,
  cilMediaRecord,
  cilMenu,
  cilMobile,
  cilMoney,
  cilMoon,
  cilNotes,
  cilOptions,
  cilPaperclip,
  cilPaperPlane,
  cilPen,
  cilPencil,
  cilPeople,
  cilPrint,
  cilPuzzle,
  cilReportSlash,
  cilSave,
  cilSettings,
  cilShare,
  cilShareAll,
  cilShareBoxed,
  cilShortText,
  cilSpeech,
  cilSpeedometer,
  cilSpreadsheet,
  cilStar,
  cilSun,
  cilTags,
  cilTask,
  cilTerrain,
  cilTrash,
  cilTruck,
  cilUnderline,
  cilUser,
  cilUserFemale,
  cilUserFollow,
  cilUserUnfollow,
  logo,
  signet,
};

export enum IconSubset {
  cilAccountLogout = 'cilAccountLogout',
  cilAlignCenter = 'cilAlignCenter',
  cilAlignLeft = 'cilAlignLeft',
  cilAlignRight = 'cilAlignRight',
  cilApplicationsSettings = 'cilApplicationsSettings',
  cilArrowBottom = 'cilArrowBottom',
  cilArrowRight = 'cilArrowRight',
  cilArrowTop = 'cilArrowTop',
  cilArrowThickToBottom = 'cilArrowThickToBottom',
  cilArrowThickToTop = 'cilArrowThickToTop',
  cilBasket = 'cilBasket',
  cilBell = 'cilBell',
  cilBold = 'cilBold',
  cilBook = 'cilBook',
  cilBookmark = 'cilBookmark',
  cilBuilding = 'cilBuilding',
  cilCalculator = 'cilCalculator',
  cilCalendar = 'cilCalendar',
  cilCash = 'cil-cash',
  cilChart = 'cilChart',
  cilChartPie = 'cilChartPie',
  cilCheck = 'cilCheck',
  cilChevronLeft = 'cilChevronLeft',
  cilChevronRight = 'cilChevronRight',
  cilCircle = 'cilCircle',
  cilCloudDownload = 'cilCloudDownload',
  cilCode = 'cilCode',
  cilCommentSquare = 'cilCommentSquare',
  cilContact = 'cilContact',
  cilContrast = 'cilContrast',
  cilCreditCard = 'cilCreditCard',
  cilCursor = 'cilCursor',
  cilDescription = 'cilDescription',
  cilDollar = 'cilDollar',
  cilDrop = 'cilDrop',
  cilEnvelopeClosed = 'cilEnvelopeClosed',
  cilEnvelopeOpen = 'cilEnvelopeOpen',
  cilFile = 'cilFile',
  cilGraph = 'cilGraph',
  cilGrid = 'cilGrid',
  cilHome = 'cilHome',
  cilHandshake = 'cilHandshake',
  cilInbox = 'cilInbox',
  cilIndentDecrease = 'cilIndentDecrease',
  cilIndentIncrease = 'cilIndentIncrease',
  cilInfo = 'cilInfo',
  cilInput = 'cilInput',
  cilItalic = 'cilItalic',
  cilJustifyCenter = 'cilJustifyCenter',
  cilLanguage = 'cilLanguage',
  cilLayers = 'cilLayers',
  cilLibraryBuilding = 'cilLibraryBuilding',
  cilList = 'cilList',
  cilListNumbered = 'cilListNumbered',
  cilLocationPin = 'cilLocationPin',
  cilLockLocked = 'cilLockLocked',
  cilMagnifyingGlass = 'cilMagnifyingGlass',
  cilMap = 'cilMap',
  cilMediaPlay = 'cilMediaPlay',
  cilMediaRecord = 'cilMediaRecord',
  cilMenu = 'cilMenu',
  cilMobile = 'cilMobile',
  cilMoney = 'cilMoney',
  cilMoon = 'cilMoon',
  cilNotes = 'cilNotes',
  cilOptions = 'cilOptions',
  cilPaperclip = 'cilPaperclip',
  cilPaperPlane = 'cilPaperPlane',
  cilPen = 'cilPen',
  cilPencil = 'cilPencil',
  cilPeople = 'cilPeople',
  cilPrint = 'cilPrint',
  cilPuzzle = 'cilPuzzle',
  cilReportSlash = 'cilReportSlash',
  cilSave = 'cilSave',
  cilSettings = 'cilSettings',
  cilShare = 'cilShare',
  cilShareAll = 'cilShareAll',
  cilShareBoxed = 'cilShareBoxed',
  cilShortText = 'cil-short-text',
  cilSpeech = 'cilSpeech',
  cilSpeedometer = 'cilSpeedometer',
  cilSpreadsheet = 'cilSpreadsheet',
  cilStar = 'cilStar',
  cilSun = 'cilSun',
  cilTags = 'cilTags',
  cilTask = 'cilTask',
  cilTerrain = 'cilTerrain',
  cilTrash = 'cilTrash',
  cilUnderline = 'cilUnderline',
  cilUser = 'cilUser',
  cilUserFemale = 'cilUserFemale',
  cilUserFollow = 'cilUserFollow',
  cilUserUnfollow = 'cilUserUnfollow',
  logo = 'logo',
  signet = 'signet',
}
