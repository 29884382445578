import { INavData } from '@coreui/angular-pro';
export const navItems: INavData[] = [
  {
    name: 'Tracker',
    url: '/tracker',
    iconComponent: { name: 'cil-speedometer' },
  },
  {
    name:  'Market',
    url: '/market',
    iconComponent: { name: 'cil-basket' },
    // badge: {
    //   color: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name:  'Posts',
    url: '/posts',
    iconComponent: { name: 'cil-basket' },
    // badge: {
    //   color: 'info',
    //   text: 'NEW'
    // },
    attributes: {
      disabled:"true"
    }
  },
  {
    name: 'Contracts',
    url: '/contracts',
    iconComponent: { name: 'cil-handshake' },
    // badge: {
    //   color: 'info',
    //   text: 'NEW'
    // }
  },
  {
    name:  'Login',
    url: '/login',
    iconComponent: { name: 'cil-input' },
  },
  {
    title: true,
    name:  'Settings'
  },
  {
    name:  'Profile',
    url: '/profile',
    iconComponent: { name: 'cil-user' },
  },
  {
    title: true,
    name:  'Library',
    class: 'mt-auto'
  },
  {
    name:  'Rulebook',
    url: '/rulebook',
    iconComponent: { name: 'cil-book' }
  }
];
