import { Component } from '@angular/core';
import { FooterComponent , NavItemComponent, NavLinkDirective, NavModule} from '@coreui/angular-pro';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
@Component({
    selector: 'app-default-footer',
    templateUrl: './default-footer.component.html',
    styleUrls: ['./default-footer.component.scss'],
    standalone: true,
    imports: [FooterComponent, NavItemComponent, NavLinkDirective, NavModule, RouterLink, RouterLinkActive]
})
export class DefaultFooterComponent extends FooterComponent {
  constructor() {
    super();
  }
}
