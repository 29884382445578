<!--sidebar-->
<c-sidebar
  #sidebar1="cSidebar"
  class="d-print-none sidebar sidebar-fixed border-end"
  colorScheme="dark"
  id="sidebar1"
  visible
>
  <c-sidebar-header class="border-bottom">
    <c-sidebar-brand [routerLink]="[]">
      <!--<svg [class]="{icon: false}" cIcon class="sidebar-brand-full" height="32" name="logo" title="LAKAL Logo"></svg> -->
      <img src = "assets/brand/lakal-logo-dark.svg" height="40" alt="LAKAL Logo"/>
      <!--<svg cIcon class="sidebar-brand-narrow" height="32" name="signet" title="LAKAL Logo"></svg> -->
    </c-sidebar-brand>
  </c-sidebar-header>

  <ng-scrollbar #scrollbar="ngScrollbar" (updated)="onScrollbarUpdate(scrollbar.state)" class="overflow" pointerEventsMethod="scrollbar" visibility="hover">
    <c-sidebar-nav #overflow [navItems]="navItemsList" dropdownMode="close" compact />
  </ng-scrollbar>
  <!-- @if (!sidebar1.narrow) {
    <c-sidebar-footer cSidebarToggle="sidebar1" class="border-top d-none d-lg-flex" toggle="unfoldable" style="cursor: pointer;">
      <button cSidebarToggler></button>
    </c-sidebar-footer>
  } -->
</c-sidebar>

<!--main-->
<div class="wrapper d-flex flex-column min-vh-100">
  <!--app-header-->
  <app-default-header [cShadowOnScroll]="'sm'"
                      class="mb-4 d-print-none header header-sticky p-0 shadow-sm"
                      position="sticky"
                      sidebarId="sidebar1" />
  <!--app-body-->
  <div class="body flex-grow-1">
    @if (systemService.calloutVisible) {
      <c-callout [color]="systemService.calloutColor" class="position-absolute z-3 opacity-100 bg-white">
        {{systemService.calloutMessage}}
      </c-callout>
    }
    <c-container breakpoint="lg" class="h-auto px-1">
      <router-outlet />
    </c-container>
  </div>
  <!--app footer-->
  <app-default-footer />
</div>
