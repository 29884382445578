import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  computed,
  DestroyRef,
  inject,
  Input,
  OnInit,
  ChangeDetectorRef,
} from '@angular/core';
import {
  AvatarComponent,
  BadgeComponent,
  BadgeModule,
  BreadcrumbRouterComponent,
  ButtonDirective,
  ColorModeService,
  ContainerComponent,
  DropdownComponent,
  DropdownDividerDirective,
  DropdownHeaderDirective,
  DropdownItemDirective,
  DropdownMenuDirective,
  DropdownToggleDirective,
  HeaderComponent,
  HeaderNavComponent,
  HeaderTogglerDirective,
  NavItemComponent,
  NavLinkDirective,
  ProgressBarDirective,
  ProgressComponent,
  SidebarToggleDirective,
  TextColorDirective,
  ThemeDirective,
  ToastBodyComponent,
  ToastComponent,
  ToasterComponent,
  ToastHeaderComponent,
  TooltipModule,
} from '@coreui/angular-pro';
import { CommonModule, NgStyle, NgTemplateOutlet } from '@angular/common';
import { ActivatedRoute, RouterLink, RouterLinkActive } from '@angular/router';
import { IconDirective } from '@coreui/icons-angular';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { delay, filter, map, tap } from 'rxjs/operators';
import { UserProfileService } from '../../../services/user-profile.service';
import { NotificationService } from 'src/app/services/notification.service';
import { TranslocoDirective, TranslocoService } from '@jsverse/transloco';
import { ContractsService } from 'src/app/services/contracts.service';
import { OrdersService } from 'src/app/services/orders.service';

@Component({
  selector: 'app-default-header',
  templateUrl: './default-header.component.html',
  changeDetection: ChangeDetectionStrategy.Default,
  standalone: true,
  imports: [
    AvatarComponent,
    BadgeComponent,
    BadgeModule,
    BreadcrumbRouterComponent,
    ButtonDirective,
    ContainerComponent,
    CommonModule,
    DropdownMenuDirective,
    DropdownHeaderDirective,
    DropdownItemDirective,
    DropdownDividerDirective,
    DropdownComponent,
    DropdownToggleDirective,
    HeaderNavComponent,
    HeaderTogglerDirective,
    IconDirective,
    NavItemComponent,
    NavLinkDirective,
    NgTemplateOutlet,
    NgStyle,
    ProgressBarDirective,
    ProgressComponent,
    RouterLink,
    RouterLinkActive,
    SidebarToggleDirective,
    TextColorDirective,
    ThemeDirective,
    ToastComponent,
    ToastHeaderComponent,
    ToastBodyComponent,
    ToasterComponent,
    TooltipModule,
    TranslocoDirective,
  ],
})
export class DefaultHeaderComponent
  extends HeaderComponent
  implements OnInit, AfterViewInit
{
  @Input() sidebarId: string = 'sidebar1';
  readonly #activatedRoute: ActivatedRoute = inject(ActivatedRoute);
  readonly #colorModeService = inject(ColorModeService);
  readonly colorMode = this.#colorModeService.colorMode;
  readonly #destroyRef: DestroyRef = inject(DestroyRef);

  readonly colorModes = [
    { name: 'light', text: 'Light', icon: 'cilSun' },
    { name: 'dark', text: 'Dark', icon: 'cilMoon' },
    { name: 'auto', text: 'Auto', icon: 'cilContrast' },
  ];
  currentMode: string = 'light';

  readonly languages = [
    { name: 'ENG', text: 'English', code: 'en' },
    { name: 'TAG', text: 'Tagalog', code: 'tl' },
    { name: 'ILC', text: 'Ilocano', code: 'il' },
    { name: 'BIS', text: 'Bisaya', code: 'bs' },
    { name: 'KAP', text: 'Kapampanga', code: 'kp' },
  ];

  readonly icons = computed(() => {
    const currentMode = this.colorMode();
    const themeMode = this.#colorModeService.getDatasetTheme() || 'light';
    if (themeMode === 'auto') {
      this.currentMode = 'light';
    } else {
      this.currentMode = themeMode;
    }
    console.log(
      'currentMode: ',
      themeMode,
      this.#colorModeService.getDatasetTheme()
    );
    return (
      this.colorModes.find((mode) => mode.name === currentMode)?.icon ??
      'cilSun'
    );
  });

  language: string = 'ENG';
  numUpdates: number = 5;
  numPosts: number = 0;
  numPayments: number = 0;
  showUpdates: boolean = false;

  // tooltips
  showMarketTracker: boolean = false;
  showMarketPlace: boolean = false;
  showMyPosts: boolean = false;
  showContracts: boolean = false;

  public newTasks = [
    { id: 0, title: 'Upgrade NPM', value: 0, color: 'info' },
    { id: 1, title: 'ReactJS Version', value: 25, color: 'danger' },
    { id: 2, title: 'VueJS Version', value: 50, color: 'warning' },
    { id: 3, title: 'Add new layouts', value: 75, color: 'info' },
    { id: 4, title: 'Angular Version', value: 100, color: 'success' },
  ];

  constructor(
    public userService: UserProfileService,
    public notificationService: NotificationService,
    private translocoService: TranslocoService,
    public contractsService: ContractsService,
    public orderService: OrdersService,
    public changeDetectorRef: ChangeDetectorRef
  ) {
    super();

    this.#colorModeService.localStorageItemName.set(
      'coreui-free-angular-admin-template-theme-default'
    );
    this.#colorModeService.eventName.set('ColorSchemeChange');

    this.#activatedRoute.queryParams
      .pipe(
        delay(1),
        map((params) => <string>params['theme']?.match(/^[A-Za-z0-9\s]+/)?.[0]),
        filter((theme) => ['dark', 'light', 'auto'].includes(theme)),
        tap((theme) => {
          this.colorMode.set(theme);
        }),
        takeUntilDestroyed(this.#destroyRef)
      )
      .subscribe();
  }

  ngOnInit(): void {
    this.randomToolTip();
  }

  ngAfterViewInit(): void {
    this.changeDetectorRef.detectChanges;
  }

  private randomToolTip() {
    let num = Math.floor(Math.random() * 100) % 4;
    switch (num) {
      case 0:
        this.showMarketTracker = true;
        setTimeout(() => {
          this.showMarketTracker = false;
        }, 10000);
        break;
      case 1:
        this.showMarketPlace = true;
        setTimeout(() => {
          this.showMarketPlace = false;
        }, 10000);
        break;
      case 2:
        this.showMyPosts = true;
        setTimeout(() => {
          this.showMyPosts = false;
        }, 10000);
        break;
      case 3:
        this.showContracts = true;
        setTimeout(() => {
          this.showContracts = false;
        }, 10000);
        break;
    }
  }

  public languageSet(lang: any) {
    this.language = lang.name;
    this.translocoService.setActiveLang(lang.code);
  }

  toggleShowUpdates() {
    this.notificationService.updateDuration();
    this.showUpdates = !this.showUpdates;
  }
  setColor(msgType: string) {
    switch (msgType) {
      case 'urgent':
      case 'danger':
        return 'danger';
      case 'warning':
        return 'warning';
      case 'info':
      case 'normal':
        return 'info';
      case 'success':
        return 'success';
      case 'update':
      default:
        return 'primary';
    }
  }

  deleteNotification(key: string | undefined) {
    if (key !== undefined)
      this.notificationService.deleteNotification(
        this.userService.business.businessID,
        key
      );
  }
}
