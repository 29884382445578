<!--<c-footer>-->
  <div>
    <a href="https://lakal.exchange/" target="_blank">LAKAL</a>
    <span> &copy; 2024 Exchange</span>
  </div>
  <c-nav>
    <c-nav-item>
      <a routerLink="/dashboard" cNavLink>Home</a>
    </c-nav-item>
    <c-nav-item>
      <a href="https://lakal.exchange/#section-about" cNavLink>About Us</a>
    </c-nav-item>
    <c-nav-item>
      <a href="https://lakal.exchange/#services" cNavLink>What we Do</a>
    </c-nav-item>
    <c-nav-item>
      <a href="https://lakal.exchange/#team" cNavLink>Partners</a>
    </c-nav-item>
    <c-nav-item>
      <a [routerLink] cNavLink>News</a>
    </c-nav-item>
    <c-nav-item>
      <a [routerLink] cNavLink>Contact Us</a>
    </c-nav-item>
    <c-nav-item>
      <a routerLink="rulebook" cNavLink>Rulebook</a>
    </c-nav-item>
  </c-nav>
  <!-- <div class="ms-auto">
    Powered by
    <a href="https://coreui.io/angular" target="_blank">
      <span> CoreUI for Angular</span>
    </a>
  </div> -->
<!--</c-footer>-->
