import {
  ApplicationConfig,
  importProvidersFrom,
  isDevMode,
} from '@angular/core';

import { provideAnimations } from '@angular/platform-browser/animations';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
  withHashLocation,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from '@angular/router';

import { DropdownModule, SidebarModule } from '@coreui/angular-pro';
import { IconSetService } from '@coreui/icons-angular';
import { routes } from './app.routes';

// firebase imports
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore,
} from '@angular/fire/firestore';
import {
  getFunctions,
  provideFunctions,
  connectFunctionsEmulator,
} from '@angular/fire/functions';
import { connectStorageEmulator, getStorage, provideStorage } from '@angular/fire/storage';
import {
  getDatabase,
  provideDatabase,
  connectDatabaseEmulator,
} from '@angular/fire/database';
//import { initializeAppCheck, ReCaptchaEnterpriseProvider, provideAppCheck } from '@angular/fire/app-check';
//import { getAnalytics, provideAnalytics, ScreenTrackingService, UserTrackingService } from '@angular/fire/analytics';

//import { getMessaging, provideMessaging } from '@angular/fire/messaging';
//import { getPerformance, providePerformance } from '@angular/fire/performance';
//import { getRemoteConfig, provideRemoteConfig } from '@angular/fire/remote-config';

import { environment } from '../environments/environment';

// import { USE_EMULATOR as USE_AUTH_EMULATOR } from '@angular/fire/compat/auth';
// import { USE_EMULATOR as USE_FIRESTORE_EMULATOR } from '@angular/fire/compat/firestore';
// import { USE_EMULATOR as USE_FUNCTIONS_EMULATOR } from '@angular/fire/compat/functions';
// import { USE_EMULATOR as USE_DATABASE_EMULATOR } from '@angular/fire/compat/database';
import { SplashScreenStateService } from './services/splash-screen-state.service';
import { provideHttpClient } from '@angular/common/http';
import { TranslocoHttpLoader } from './transloco-loader';
import { provideTransloco } from '@jsverse/transloco';
import { provideServiceWorker } from '@angular/service-worker';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(
      routes,
      withRouterConfig({
        onSameUrlNavigation: 'reload',
      }),
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
        anchorScrolling: 'enabled',
      }),
      withEnabledBlockingInitialNavigation(),
      withViewTransitions(),
      withHashLocation()
    ),
    importProvidersFrom(SidebarModule, DropdownModule),
    IconSetService,
    SplashScreenStateService,
    provideAnimations(),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators)
        connectAuthEmulator(auth, 'http://localhost:9098', {
          disableWarnings: true,
        });
      return auth;
    }),
    provideFirestore(() => {
      const afDB = getFirestore((environment.firebaseConfig as any).databaseID);
      if (environment.useEmulators)
        connectFirestoreEmulator(afDB, '127.0.0.1', 8080);
      return afDB;
    }),
    provideStorage(() => {
      const fbStorage = getStorage();
      if(environment.useEmulators)
        connectStorageEmulator(fbStorage, '127.0.0.1', 9199);
      return fbStorage;
    }),
    provideFunctions(() => {
      const fbFunctions = getFunctions();
      if (environment.useEmulators)
        connectFunctionsEmulator(fbFunctions, '127.0.0.1', 5001);
      return fbFunctions;
    }),
    provideDatabase(() => {
      const afDB = getDatabase();
      if (environment.useEmulators)
        connectDatabaseEmulator(afDB, '127.0.0.1', 9000);
      return afDB;
    }),
    provideHttpClient(),
    provideTransloco({
      config: {
        availableLangs: ['en', 'tl', 'il', 'bs', 'kp'],
        defaultLang: 'en',
        // Remove this option if your application doesn't support changing language in runtime.
        reRenderOnLangChange: true,
        prodMode: !isDevMode(),
      },
      loader: TranslocoHttpLoader,
    }),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
};
