import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterOutlet,
  ActivatedRoute,
} from '@angular/router';
import { Title } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { IconSetService } from '@coreui/icons-angular';
import { iconSubset } from './icons/icon-subset';
import {
  Auth,
  authState,
  User,
  isSignInWithEmailLink,
  signInWithEmailLink,
  getAuth,
} from '@angular/fire/auth';
import { Subscription } from 'rxjs';
import { UserProfileService } from './services/user-profile.service';
import { SystemService } from './services/system.service';
import { SplashScreenComponent } from './components/splash-screen/splash-screen.component';
import { SplashScreenStateService } from './services/splash-screen-state.service';
import { NotificationService } from './services/notification.service';
import { SwUpdate } from '@angular/service-worker';
import { ContractsService } from './services/contracts.service';
import { OrdersService } from './services/orders.service';
// template: '<router-outlet /><app-splash-screen/>',
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  standalone: true,
  imports: [RouterOutlet, CommonModule, SplashScreenComponent],
})
export class AppComponent implements OnInit, OnDestroy {
  title = 'LAKAL Trading Platform';
  private auth: Auth = inject(Auth);
  authState$ = authState(this.auth);
  authStateSubscription: Subscription;

  constructor(
    private router: Router,
    private titleService: Title,
    private iconSetService: IconSetService,
    private userProfile: UserProfileService,
    private systemService: SystemService,
    private contractService: ContractsService,
    private orderService: OrdersService,
    private notificationService: NotificationService,
    private splashScreenStateService: SplashScreenStateService,
    private activatedRoute: ActivatedRoute,
    private swUpdate: SwUpdate
  ) {
    this.titleService.setTitle(this.title);
    // iconSet singleton
    this.iconSetService.icons = { ...iconSubset };

    // setting up authStateSubscription;
    this.authStateSubscription = this.authState$.subscribe(
      (aUser: User | null) => {
        //handle auth state changes here. Note, that user will be null if there is no currently logged in user.
        console.log('APP: authState', aUser);
        if (aUser !== null) {
          // someone is logged in
          this.checkLastLogin();
          this.userProfile.checkUser(aUser).then(
            (result: any) => {
              if (result['success'] == true) {
                console.log('APP: user profile check');
                this.userProfile.checkBusiness().then((result: any) => {
                  if (result['success'] == true) {
                    console.log('APP: business check');
                    this.loadMarketPlace(this.userProfile.business.businessID);
                  } else {
                    console.log('APP: no business');
                  }
                });
              } else {
                console.log('APP: no user profile');
                this.userProfile.resetUser();
              }
            },
            (err) => {
              console.log('APP: got a user Error', err);
              this.userProfile.resetUser();
            }
          );
        } else {
          // there is no logged in user
          this.userProfile.resetUser();
          localStorage.removeItem('lastLogin');
        }
      }
    );

    // load lookups
    console.log('App: loading lookups');
    this.systemService.checkLookups();
    this.systemService.refreshLookups();
    // console.log("Barangays", this.systemService.getBarangays());
    //console.log("APP: href", location.href)
    const paramMatch = location.href.match(/\?(.+)/);
    if (paramMatch) {
      this.checkForEmailLink(location.href, paramMatch);
    }
  }

  ngOnInit(): void {
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
    });
    console.log('swUpdate.isEnabled: ' + this.swUpdate.isEnabled);
    // swUpdate check for new version
    if (this.swUpdate.isEnabled) {
      setInterval(() => {
        console.log('Checking for updates');
        this.swUpdate.checkForUpdate().then((result) => {
          console.log('Check for update result', result);
        });
      }, 1000 * 60 * 60); // check for updates every hour
      this.swUpdate.versionUpdates.subscribe(async (res) => {
        console.log('Version Update', res);
        switch (res.type) {
          case 'VERSION_DETECTED':
            this.systemService.doCallout(
              'New version available. Your app will be reloaded after update',
              'info'
            );
            // alert("New version available. Your app will be reloaded after update");
            break;
          case 'VERSION_READY':
            this.swUpdate.activateUpdate().then((result) => {
              if (result) {
                console.log('New version ready for activation', result);
                // alert(
                //   'New version ready for activation. Your app will now reload'
                // );
                this.systemService.doCallout(
                  'New version ready for activation. Your app will now reload',
                  'info'
                );
              } else {
                // alert('Failed to update app version'); // hide this later on
                console.log('No new version detected');
              }
              alert('Reloading app'); // hide this later on
              window.location.reload();
            });
            break;
          default:
            break;
        }
      });
    }
    // it is meant to ensure that UI/UX starts with home page first
    // uncomment this during production
    // this.router.navigateByUrl('/');

    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    setTimeout(() => {
      this.splashScreenStateService.stop();
    }, 5000);

    // this.activatedRoute.snapshot.data['itemsList']
    //   .subscribe((res:string[]) => {
    //      console.log("AppComponent: got resolved",{ res });
    //   })
  }

  checkForEmailLink(href: string, paramMatch: string[]) {
    const params = paramMatch[1];

    if (isSignInWithEmailLink(this.auth, href)) {
      console.log('APP: Caught the signInWithEmailLink');

      // The client SDK will parse the code from the link for you.
      let emailForSignIn = localStorage.getItem('emailForSignIn') || '';
      signInWithEmailLink(this.auth, emailForSignIn, href)
        .then((result) => {
          console.log('APP: signIn');
          // Clear email from storage.
          window.localStorage.removeItem('emailForSignIn');
          // You can access the new user by importing getAdditionalUserInfo
          // and calling it with result:
          // getAdditionalUserInfo(result)
          // You can access the user's profile via:
          // getAdditionalUserInfo(result)?.profile
          // You can check if the user is new or existing:
          // getAdditionalUserInfo(result)?.isNewUser
        })
        .catch((error) => {
          console.log('App: Error with signInLink:', error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        });
    } else {
      console.log(
        'APP: did not get an EmailLink',
        window.location.href,
        window.location.search
      );
    }
  }

  async loadMarketPlace(businessID: string = '') {
    console.log('APP: loading marketplace', businessID);
    // open the spinner
    console.log('APP: loading contracts');
    await this.contractService.watchAllMyBuyContracts(businessID);
    await this.contractService.watchAllMySellContracts(businessID);
    console.log('APP: loading orders');
    await this.orderService.watchAllMyBuyOrders(businessID);
    await this.orderService.watchAllMySellOrders(businessID);
    console.log('APP: loading offers');
    await this.orderService.watchAllMyOffers(businessID);
    console.log('APP: loading notifications');
    await this.notificationService.readMyNotifications(businessID);
    // close the spinner
  }
  checkLastLogin() {
    const nowDate = new Date();
    const currentLoginTime = nowDate.getTime();
    let lastLogin = localStorage.getItem('lastLogin');
    if (lastLogin) {
      console.log('APP: last login', lastLogin);
      const lastLoginTime = parseInt(lastLogin);
      const timeDiff = currentLoginTime - lastLoginTime;
      console.log('APP: time diff', timeDiff);
      // save the new lastLogin
      localStorage.setItem('lastLogin', currentLoginTime.toString());
      // check now if they have been logged out for too long
      if (timeDiff > 1000 * 60 * 60 * 1) {
        // more than 8 hour/s // test for 1 hour
        console.log('APP: more than 8 hours');
        // logout the user and alert them that they have been logged out
        this.auth
          .signOut()
          .then((result) => {
            console.log('APP: logged out', result);
            this.systemService.doCallout(
              'You have been logged out due to inactivity',
              'warning'
            );
            // alert("You have been logged out due to inactivity");
            setTimeout(() => {
              window.location.reload();
            }, 5000);
          })
          .catch((err) => {
            console.log('APP: logout error', err);
          });
      } else {
        console.log('APP: less than 8 hours');
      }
    } else {
      console.log('APP: no last login');
      localStorage.setItem('lastLogin', currentLoginTime.toString());
    }
  }
  ngOnDestroy() {
    // when manually subscribing to an observable remember to unsubscribe in ngOnDestroy
    this.authStateSubscription.unsubscribe();
  }
}
